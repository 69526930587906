.App {
  text-align: center;
}

.container {
  width: 30vw;
}

@media (max-width: 768px){
  .container {
    width: 90vw;
  }
}


@media (min-width: 768px) and (max-width: 1024px){
  .container {
    width: 70vw;
  }
}

button.lfg {
  color: #000;
  font-weight: 600;
  animation: pulse 3s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    background-color: hsla(0, 100%, 50%, 0.2);
  }
  25% {
    background-color: hsla(120, 100%, 50%, 0.2);
  }
  50% {
    background-color: hsla(240, 100%, 50%, 0.2);
  }
  75% {
    background-color: hsla(60, 100%, 50%, 0.2);
  }
  100% {
    background-color: hsla(0, 100%, 50%, 0.2);
  }
}
